<template>
	<div class="QY">
		<div class="home_center">

			<div class="">
				<div class="center_left">
					<div class="left_btn">
						<router-link to="/QY_msg">
						<el-button size="mini">
							<img src="../../assets/img/bi.png" class="fengx" alt="">
						</el-button>
						</router-link>
						<el-button size="mini" style="margin-left: 10px;" class="fxbtn">
							<img src="../../assets/img/fengx.png" class="fengx" alt="">
							<div class="sharemenu">
								<div @click="share(user_id,'facebook',userInfo.business_name)">Facebook</div>
								<div @click="share(user_id,'Linkedin',userInfo.business_name)">LinkedIn</div>
								<div @click="share(user_id,'twitter',userInfo.business_name)">Twitter</div>
							</div>
						</el-button>
					</div>
					<router-link to="/QY_msg" class="left_img">
						<img :src="userInfo.avatar_url"  alt="" style="object-fit: cover;">
					</router-link>
					
					<div class="left_name">
						{{userInfo.business_name}}
					</div>
					<!-- <div class="left_card">
						已认证
					</div> -->
					<div class="fansfollow">
						<router-link to="/QY_guanzhu" class="item">{{userInfo.follows}} {{$t('FOLLOW')}}</router-link>
						<router-link to="/QY_guanzhu?showIndex=2" class="item">{{userInfo.fans}} {{$t('FANS')}}</router-link>
					</div>
					<div class="left_list" style="margin-top: 20px;">
						<div class="list_text">
							<!-- 行业归属 -->{{$t('QY.HYGS')}}：
						</div>
						<div class="list_msg">
							{{userInfo.industry}}
						</div>
					</div>
					<div class="left_list">
						<div class="list_text">
							<!-- 人员规模 -->{{$t('QY.RYGM')}}：
						</div>
						<div class="list_msg">
							{{userInfo.employee_num}}
						</div>
					</div>
					<div class="left_list">
						<div class="list_text">
							<!-- 评分次数 -->{{$t('QY.PFCS')}}：
						</div>
						<div class="list_msg">
							{{userInfo.comment_total}}
						</div>
					</div>
					<div class="left_list">
						<div class="list_text">
							<!-- 雇员评分 -->{{$t('QY.GYPF')}}：
						</div>
						<div class="list_msg">
							<el-rate v-model="userInfo.score" disabled void-color="#89a3cc" disabled-void-color="#89a3cc" :colors="colors"></el-rate>
						</div>
					</div>
					<!-- <div class="left_list">
						<div class="list_text">
							{{$t('OTHERLINK')}}：
						</div>
						<div class="list_msg">
							{{userInfo.Linkedin_url}}
						</div>
					</div> -->
					<div class="left_list">
						<div class="list_text">
							<!-- 企业官网 -->{{$t('QY.QYGW')}}：
						</div>
						<div class="list_msg">
							{{userInfo.bus_website}}
						</div>
					</div>
					<!-- <div class="left_list">
						<div class="list_text">
							{{$t('QY.ZT')}}：
						</div>
						<div class="list_msg">
							<el-switch
							  v-model="value"
							  active-color="#13ce66"
							  inactive-color="#ff4949">
							</el-switch>
						</div>
					</div> -->
					<!-- <el-button type="primary" class="last_btn">发消息</el-button> -->
				</div>
				<div class="center_left">
					<div class="center_left_address">
						<img src="../../assets/img/address2.png" class="address_img" alt="">
						{{userInfo.city}}
					</div>
					<div class="center_left_title">
						<!-- 企业简介 -->{{$t('QY.QYJJ')}}
					</div>
					<div class="center_left_msg">
						{{userInfo.description}}
					</div>
				</div>
			</div>


			<div class="center_people">
				<div class="center_top">
					<div class="top_title">
						<div class="title_name">
							<!-- 发布任务数 -->{{$t('QY.FBRWS')}}：{{jobStat.jobCount}}
						</div>
						<!-- <div class="title_name">
							{{$t('QY.PJXC')}}：{{jobStat.avgSalary}}¥/{{$t('HH')}}
						</div> -->
						<!-- <img src="../../assets/img/more2.png" class="more" alt=""> -->
					</div>
					<div class="top_id">
						<div class="id_box" v-for="item in jobStat.jobCateResult" :key="item.cate_third_id">
							{{item.cate_name}}
							<div class="num">
								{{item.total}}
							</div>
						</div>
						
					</div>
				</div>
				<div class="activity_box" v-for="(item,index) in jobList" :key="item.job_id">
					<img :src="item.avatar_url" class="box_img" alt="">
					<div class="box_msg">
						<div class="msg_name">
							{{item.job_name}}
						</div>
						<!-- <div class="msg_msg">
							东京三菱UFJ，IT
						</div> -->
						<div class="msg_msg">
							{{item.job_desc}}
						</div>
						<div class="msg_text">
							<!-- 必要技能 -->{{$t('BYJN')}}：{{item.skills.join(' , ')}}
						</div>
					</div>
					<div class="box_center">
						<div class="center_text">
							<div class="text_text">
								<!-- 时间 -->{{$t('TIME')}}
							</div>
							<div class="text_box">
								<template v-if="item.oper_time_method==0">
									{{item.oper_time_val}}<!-- 天 -->{{$t('TIAN')}}
								</template>
								<template v-if="item.oper_time_method==1">
									{{item.oper_time_val}}
								</template>
								<template v-if="item.oper_time_method==3">
									{{$util.getUnitVal(operTimeMethod,item.oper_time_method)}}
								</template>
							</div>
						</div>
						<div class="center_text">
							<div class="text_text">
								<!-- 委托方式 -->{{$t('job_detail.WTFS')}}
							</div>
							<div class="text_box">
								{{$util.getUnitVal(jobBelong,item.job_belong_method)}}
							</div>
						</div>
						<div class="center_text">
							<div class="text_text">
								<!-- 佣金 -->{{$t('YJ')}}
							</div>
							<div class="text_box">
								{{item.salary_low}} - {{item.salary_high}} {{item.salary_unit}}
							</div>
						</div>
						<div class="center_text">
							<div class="text_text">
								<!-- 需要人数 -->{{$t('other.XYRS')}}
							</div>
							<div class="text_box">
								{{item.job_num_people}}<!-- 人 -->{{$t('REN')}}
							</div>
						</div>
					</div>
					<div class="box_btn">
						<div class="btn">
							<img src="../../assets/img/fengx.png" class="fenx" alt="">
							<!-- 分享 -->{{$t('task.FX')}}
							<div class="sharemenu">
								<div @click.stop="sharejob(item.job_id,'facebook',item.job_name)">Facebook</div>
								<div @click.stop="sharejob(item.job_id,'Linkedin',item.job_name)">LinkedIn</div>
								<div @click.stop="sharejob(item.job_id,'twitter',item.job_name)">Twitter</div>
							</div>
						</div>						
						<div class="btn" style="cursor: pointer;" @click.stop="fav(item.job_id,index)">
							
							<img src="../../assets/img/biaoq2.png" class="fenx" alt="" v-if="item.is_collect==1">
							<img src="../../assets/img/biaoq.png" class="fenx" alt="" v-else>
							<!-- 保存 -->{{$t('FAV')}}
						</div>
					</div>
				
				</div>
				<!-- <div class="people_list">
					<img src="../../assets/img/caret-down.png" class="down" alt="">
					展开查看更多任务
				</div> -->
				<div class="menu_box">
					<el-badge :value="commentTotal" :hidden="commentTotal==0" class="item">
					  <div class="menu" :class="{active:tabIndex==1}" @click="tabIndex=1"><!-- 案件评价 -->{{$t('QY.AJPJ')}}</div>
					</el-badge>
					<el-badge :value="trendTotal" :hidden="trendTotal==0" class="item">
					  <div class="menu" :class="{active:tabIndex==2}" @click="tabIndex=2"><!-- 全部动态 -->{{$t('QY.QBDT')}}</div>
					</el-badge>
					<!-- <el-badge :value="fansTotal" class="item">
					  <div class="menu" :class="{active:tabIndex==3}" @click="tabIndex=3">{{$t('task.TDFS')}}</div>
					</el-badge>
					<el-badge :value="fllowTotal" class="item">
					  <div class="menu" :class="{active:tabIndex==4}" @click="tabIndex=4">{{$t('task.TDGZ')}}</div>
					</el-badge> -->
					
				</div>
				
				<div class="center_pin_list">
					<template v-if="tabIndex==1">
						<!-- 案件评价 -->
						<div class="pin_list" v-for="item in commentList" :key="item.job_id">
							<div class="list_top">
								<div class="top_left">
									<div class="left_title">
										{{item.job_name}}
										<div class="title_card" v-if="item.job_belong_method==1">
											<!-- 三方 -->{{$t('task.SF')}}：{{item.entrust_name}}
										</div>
									</div>
									<div class="left_card">
										<div class="card1" v-for="skill in item.skills" :key="skill">
											{{skill}}
										</div>
										<div class="card2" v-if="item.fast_method==1">
											<!-- 急聘 -->{{$t('job.JP')}}
										</div>
									</div>
									<div class="left_time">
										<!-- 发布时间 -->{{$t('FBSJ')}}：{{item.create_time}}
									</div>
								</div>
								<div class="top_right">
									<div class="right_list">
										<div class="list_text">
											<!-- 评分 -->{{$t('QY.PF')}}
										</div>
										<div class="list_card">
											<el-rate :value="item.job_score" disabled void-color="#666" disabled-void-color="#666"></el-rate>
										</div>
									</div>
									<div class="right_list">
										<div class="list_text">
											{{$util.getUnitVal(payMethod,item.pay_method)}}
										</div>
										<div class="list_card">
											{{item.salary_low}} - {{item.salary_high}} {{item.salary_unit}}
										</div>
									</div>
									<div class="right_list">
										<div class="list_text">
											<!-- 完成时间 -->{{$t('QY.WCSJ')}}
										</div>
										<div class="list_card">
											{{item.completed_time}}
										</div>
									</div>
								</div>
							</div>
							<div class="list" v-for="comment in item.employeeCommentList" :key="comment.job_comment_id">
								<div class="list_left">
									<img :src="comment.employee_avatar_url" class="left_user" alt="">
									<div class="left_msg">
										<div class="msg_name">
											{{comment.employee_name}}
										</div>
										<div class="msg_text">
											{{comment.employer_content}}
										</div>
									</div>
								</div>
								<div class="top_right">
									<div class="right_list">
										<div class="list_text">
											<!-- 评分 -->{{$t('QY.PF')}}
										</div>
										<div class="list_card">
											<el-rate :value="comment.employee_score" disabled void-color="#666" disabled-void-color="#666"></el-rate>
										</div>
									</div>
									<div class="right_list">
										<div class="list_text">
											{{$util.getUnitVal(payMethod,item.pay_method)}}
										</div>
										<div class="list_card">
											{{item.salary_low}} - {{item.salary_high}} {{item.salary_unit}}
										</div>
										
									</div>
									<div class="right_list">
										<div class="list_text">
											<!-- 完成时间 -->{{$t('QY.WCSJ')}}
										</div>
										<div class="list_card">
											{{item.completed_time}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
					<template v-if="tabIndex==2">
					<!-- 全部动态 -->
					<div class="home_list" v-for="(item,index) in trendList" :key="item.trend_id">
						<div  @click="todetail(item.trend_id)">
						<div class="list_title">
							<img :src="item.avatar_url" class="title_img" alt="">
							<div class="list_user">
								<div class="user">
									{{item.open_name}}
								</div>
								<div class="user_msg">
									{{item.create_time}}
								</div>
							</div>
							<!-- <el-button type="primary"  size="small">关注</el-button> -->
						</div>
						<div class="list_text">
							{{item.content}}
						</div>
						<div class="img_list" v-if="item.media_files" >
							<!-- <img :src="img" class="img" alt="" v-for="img in item.media_files" :key="img"> -->
							<el-image :src="img" class="img" v-for="img in item.media_files" :key="img"
							   fit="contain" :preview-src-list="item.media_files" @click.stop="">
							  </el-image>
						</div>
						<div class="like_box">
							<img src="../../assets/img/like2.png" class="like_img" alt="">
							{{item.favor_desc}}
						</div>
						</div>
						<div class="btn_box">
							<div class="btn_left">
								<div class="left_menu" style="cursor: pointer;" @click="zan(item.trend_id,index)">
									
									<img src="../../assets/img/home_zan1.png" class="btn1" alt="" v-if="item.is_favor==1" >
									<img src="../../assets/img/home_zan2.png" class="btn1" alt="" v-else>
									{{item.favor_count}}
								</div>
								<div class="left_menu" style="cursor: pointer;" @click="toggleComment(item.trend_id)">
									<img src="../../assets/img/icon_msg.png" class="btn2" alt="">
									{{item.comment_count}}
								</div>
								<div class="left_menu">
									<img src="../../assets/img/fengx.png" class="btn3" alt="">
									{{item.share_count}}
									<div class="sharemenu">
										<div @click="shareTrend(item.trend_id,'facebook',item.content)">Facebook</div>
										<div @click="shareTrend(item.trend_id,'Linkedin',item.content)">LinkedIn</div>
										<div @click="shareTrend(item.trend_id,'twitter',item.content)">Twitter</div>
									</div>
								</div>
							</div>
							<img src="../../assets/img/more2.png" class="right_btn" alt="" style="cursor: pointer;" @click="todetail(item.trend_id)">
						</div>
						<div class="pin_list2" v-if="trend_id==item.trend_id">
							<div class="list_box" v-for="(comment,cindex) in comment_list" :key="comment.trend_comment_id">
								<img :src="comment.avatar_url" class="box_img" alt="" @click.stop="toUserPage(comment.user_id,comment.user_role)">
								<div class="box_right">
									<div class="right_user">
										<div class="user_name" @click.stop="toUserPage(comment.user_id,comment.user_role)">
											<div class="name">
												{{comment.open_name}}<template v-if="comment.profession">/ {{comment.profession}}</template> <template v-if="comment.reply_comment_id"> &gt; {{comment.reply_open_name}}</template>
							
											</div>
											<div class="name_time">
												{{$util.rTime(comment.create_time)}}
											</div>
										</div>
										<div class="flex-item"></div>
										<div class="user_btn">
											<!-- <img src="../../assets/img/home_zan.png" class="btn1" alt=""> -->
											<img src="../../assets/img/home_zan1.png" class="btn1" style="cursor: pointer;" v-if="comment.is_favor==1" alt="" @click="zanComment(comment.trend_comment_id,cindex)">
											<img src="../../assets/img/home_zan2.png" class="btn1" style="cursor: pointer;" alt="" v-else @click="zanComment(comment.trend_comment_id,cindex)">
											<img src="../../assets/img/icon_msg.png" class="btn2" style="cursor: pointer;" alt="" @click="openhuifu(comment.trend_comment_id,comment.open_name)">
											<!-- <img src="../../assets/img/home_btn4.png" class="btn3" alt=""> -->
										</div>
									</div>
									<div class="right_msg">
										{{comment.content}}
									</div>
									<!-- 评论回复 -->
									<!-- <div class="right_more">
										<div class="more_user">
											Sota Takeda 
										</div>
										等人
										<div class="more_user">
											共10条回复
										</div>
									</div> -->
								</div>
							</div>
							<div class="pin_more">
								<el-button @click="getComment">{{comment_isLast==0?$t('CKGD'):''}}</el-button>
							</div>
						</div>
						
					</div>
					</template>
				</div>
				
				
			</div>
			<!-- 消息列表 -->
			<Homeright></Homeright>
		</div>
		
		<!-- 回复弹窗 -->
		<Reply ref="huifu" :id="reply_comment_id" :trend_id="trend_id" :name="reply_comment_name" @send="handleSend"></Reply>
		<!-- 全部评论 -->
		<!-- <Pinlun></Pinlun> -->
	</div>
</template>

<script>
	import Homeright from '../../components/home/home_right.vue';
	import Reply from '../../components/home/reply.vue';
	import scroll from '@/utils/scroll.js';
	import {debounce} from '@/utils/debounce.js';
	export default {
		components: {
			Homeright,
			Reply
		},
		data() {
			return {
				
				colors:['#FFFFFF', '#FFFFFF', '#FFFFFF'],
				userInfo:{},
				jobStat:{},
				commentList:[],
				jobList:[],
				trendList:[],
				
				jobBelong:[],
				entrust:[],
				jobNature:[],
				payMethod:[],
				tabIndex:1,
				trend_id:'',
				
				comment_pageSize:15,
				comment_currentPage:1,
				comment_isLast:0,
				comment_list:[],
				
				commentTotal:0,
				trendTotal:0,
				
				fansTotal:0,
				fllowTotal:0,
				
				
				reply_comment_id:0,
				reply_comment_name:'',
			};
		},
		created() {
		
			this.getDetail();
			this.getUnit();
		},
		methods: {
			//回复评论成功
			handleSend(){
				this.getCommentList('reload');
			},
			//打开回复评论弹框
			openhuifu(id,name){				
				this.reply_comment_id=id;
				this.reply_comment_name=name;
				this.$refs.huifu.open();
			},
			//动态详情
			todetail(id){
				this.$router.push({path:'/home',query:{trend_id:id}});
			},
			share:function(id,type,title){
				var href=this.$config.website+'qypage?user_id='+id;
				this.$util.share(href,type,title);
			},
			sharejob:function(id,type,title){
				var href=this.$config.website+'job_detail?job_id='+id;				
				this.$util.share(href,type,title);
			},
			shareTrend:function(id,type,title){
				var href=this.$config.website+'home?trend_id='+id;
				this.$request.get('/api/trend/share',{trend_id:id});
				this.$util.share(href,type,title);
			},
			//收藏工作
			async fav(id,index){
				let res=await this.$request.get('/api/custJobManage/collectJob',{job_id:id});				
				this.list[index].is_collect=this.list[index].is_collect==1?0:1;				
			},
			//点赞评论
			async zanComment(id,index){
				let res=await this.$request.get('/api/trend/favorComment',{trend_comment_id :id});				
				this.comment_list[index].is_favor=this.comment_list[index].is_favor==1?0:1;
				
			},
			//点赞动态
			async zan(id,index){
				
				let res=await this.$request.get('/api/trend/favor',{trend_id:id});
				// this.trendList[index].is_favor=this.trendList[index].is_favor==1?0:1;
				// let n=this.list[index].is_favor==1?1:-1;
				// this.trendList[index].favor_count+=n;
				this.getTrendList('reload');
						
			},
			//展开收起评论
			toggleComment(trend_id){
				if(this.trend_id==trend_id){
					this.trend_id=0;								
				}else{
					this.trend_id=trend_id;					
					this.getComment('reload');
				}
			},
			//获取动态评论列表
			async getComment(t){
				if(t=='reload'){
					this.comment_currentPage=1;
					this.comment_isLast=0;					
				}
				if(this.comment_isLast==1){
					return;
				}
				let res=await this.$request.post('/api/trend/commentPage',{trend_id:this.trend_id,currentPage:this.comment_currentPage,pageSize:this.comment_pageSize});
				
				
				
				if(t=='reload'){
					this.comment_list=res.data.records;			
				}else{
					if(res.data.records.length>0){
						this.comment_list=[...this.comment_list,...res.data.records];
					}						
				}
				if(this.comment_currentPage>=res.data.totalPage){
					this.comment_isLast=1;
				}else{
					this.comment_currentPage+=1;
				}
			},
			//获取字典数据
			async getUnit(){
				let res=await this.$request.get('/api/sysdict/getDictList');
				this.jobNature=res.data.job_nature.data;
				this.entrust=res.data.entrust_method.data;
				this.jobBelong=res.data.job_belong_method.data;
				this.payMethod=res.data.pay_method.data;
				
			},
			//任务
			async getJobList(){
				let res = await this.$request.get('/api/job/getBusJobList',{user_id:this.userInfo.user_id});
				this.jobList=res.data.records;
			},
			//动态
			async getTrendList(){
				let res = await this.$request.get('/api/trend/getBusTrendList',{user_id:this.userInfo.user_id});
				this.trendList=res.data.records;
				this.trendTotal=res.data.records.length;
			},
			//评价
			async getCommentList(){
				let res = await this.$request.get('/api/job/getBusJobCommentList',{user_id:this.userInfo.user_id});
				this.commentList=res.data.records;
				this.commentTotal=res.data.records.length;
			},
			async getCout(){				
				let res = await this.$request.get('/api/job/getBusJobStat',{user_id:this.userInfo.user_id});
				this.jobStat=res.data;
			},
			async getDetail(){
				let res = await this.$request.get('/api/bususer/getBusInfo');
				this.userInfo=res.data;
				
				this.getCout();
				this.getJobList();
				this.getCommentList();
				this.getTrendList();
				
				// let res1=await this.$request.get('/api/friends/fllowCount');
				// let res2=await this.$request.get('/api/friends/fansCount');
				// this.fansTotal=res2.data;
				// this.fllowTotal=res1.data;
			},
			search: debounce(function(e) {
				console.log(e);
				this.getList('reload');
			}),
			//获取工作
			async getList(t) {

				if (t == 'reload') {
					this.listData.currentPage = 1;
					this.isLast = 0;
					this.list = [];
				}
				if (this.isLast == 1) {
					return;
				}
				let res = await this.$request.post('/api/job/findPage', this.listData);
				if (t == 'reload') {
					this.list = [];
				}
				if (res.data.records.length > 0) {
					this.list = [...this.list, ...res.data.records];
				}
				if (this.listData.currentPage >= res.data.totalPage) {
					this.isLast = 1;
				} else {
					this.listData.currentPage += 1;
				}

			}

		}
	};
</script>

<style lang="less" scoped>
	.fxbtn{position: relative;
		&:hover .sharemenu{display: block;}
	}
	.fansfollow{border:1px solid #fff; border-radius: 5px;display: flex;align-items: center;
		.item{flex:1;margin:6px 0; display: block; line-height: 20px;text-align: center;font-size: 13px;border-right: 1px solid #fff;color: #fff;
			&:last-child{border-right: none;}
		}
	}
	.QY {
		min-height: 100%;
		background-color: #212121;
		
		.center_left {
			width: 286px;
			min-height: 233px;
			background: #567BB6;
			border-radius: 3px;
			padding: 30px;
			margin-bottom: 20px;
			box-sizing: border-box;
			// display: flex;
			// align-items: center;
			// flex-direction: column;
			color: #fff;
			.left_btn{
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				.fengx{
					width: 12px;
					height: 12px;
				}
				.el-button{
					background-color: #567BB6;
					border-radius: 7px;
					color: #FFFFFF;
					border: 1px solid #FFFFFF;
					
				}
			}
			.left_img{
				display: block;
				text-align: center;
				margin: 20px auto 10px;
				img{
					width: 70px;
					height: 70px;
					border-radius: 50%;
					border: 1px solid #979797;
				}
			}
			.left_name{
				font-size: 15px;
				color: #fff;
				margin-bottom: 10px;
				text-align: center;
			}
			.left_card{
				width: 80px;
				height: 20px;
				text-align: center;
				line-height: 20px;
				color: #fff;
				background: #B65664;
				border-radius: 9px;
				font-size: 14px;
				margin:0 auto 10px;
			}
			.left_list{
				width: 220px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 12px;
				margin-bottom: 10px;
				.list_msg{flex:1;text-align: right;}
			}
			.last_btn{
				width: 220px;
				background-color: #fff;
				border: 1px solid #fff;
				color: #567BB6;
			}
			.center_left_address{
				padding: 10px;
				border: 1px solid #fff;
				display: flex;
				align-items: center;
				border-radius: 5px;
				font-size: 12px;
				line-height: 1.3;
				.address_img{
					width: 17px;
					height: 17px;
					margin-right: 10px;
				}
			}
			.center_left_title{
				
				height: 40px;
				line-height: 40px;
				font-size: 15px;
				border-bottom: 1px solid #fff;
				margin-bottom: 10px;
				margin-top: 15px;
			}
			.center_left_msg{
				font-size: 14px;
				line-height: 1.3;
			}
		
		}
		
		.home_center {
			display: flex;
			justify-content: flex-end;
		}

		.center_people {
			width: 1024px;
			
			margin:0 10px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			// align-items: center;
			
			color: #FFFFFF;

			.center_top {
				
				background-color: #000000;
				padding: 25px;
				border-radius: 7px;
				margin-bottom: 10px;

				.top_title {
					display: flex;
					align-items: center;
					justify-content: space-between;
					font-size: 19px;
					margin-bottom: 10px;

					.more {
						flex-shrink: 0;
						margin-left: 30px;
						width: 27px;
						height: 27px;
					}
				}

				.top_id {
					display: flex;
					// justify-content: space-between;
					align-items: center;
					.id_box{
						min-width: 160px;
						height: 26px;
						border-radius: 7px;
						border: 1px solid #567BB6;
						display: flex;
						align-items: center;
						justify-content: space-between;
						padding: 0 5px;
						box-sizing: border-box;
						font-size: 12px;
						margin-right: 10px;
						.num{
							width: 35px;
							border-left: 1px solid #567BB6;
							text-align: center;
							flex-shrink: 0;
						}
					}
					
				}
			}

			.activity_box {
				width: 100%;
				padding: 20px;
				background-color: #000000;
				border-radius: 5px;
				margin: 10px 0;
				display: flex;
				justify-content: space-between;
				margin-bottom: 20px;
				box-sizing: border-box;
			
				.box_img {
					width: 116px;
					height: 116px;
					border-radius: 3px;
					object-fit: cover;
				}
			
				.box_msg {
					flex:1;
					margin:0 15px;
					//width: 370px;
					font-size: 15px;
					color: #fff;
			
					.msg_name {
						text-overflow: -o-ellipsis-lastline;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						line-clamp: 2;
						-webkit-box-orient: vertical;
						line-height: 1.3;
						margin-bottom: 10px;
					}
			
					.msg_msg {
						width: 170px;
						font-size: 12px;
						text-overflow: -o-ellipsis-lastline;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						line-clamp: 2;
						-webkit-box-orient: vertical;
						line-height: 1.3;
						margin-bottom: 10px;
					}
			
					.msg_text {
						font-size: 12px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			
				.box_center {
					//width: 240px;
					border-left: 1px solid #606060;
					border-right: 1px solid #606060;
					padding: 0 10px;
					color: #fff;
					font-size: 10px;
			
					.center_text {
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 5px;
			
						.text_text {
							//width: 50px;
							flex-shrink: 0;
							margin-right: 10px;
						}
			
						.text_box {
							border-radius: 3px;
							width: 170px;
							line-height: 26px;
							border: 1px solid #344A6E;
							padding-left: 5px;
			
						}
					}
				}
			
				.box_btn {
					margin-left: 10px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					//width: 40px;
					color: #fff;
					font-size: 10px;
			
					.btn {
						display: flex;
						flex-direction: column;
						align-items: center;
						margin: 10px 0;
			
						.fenx {
							width: 20px;
							height: 20px;
							margin-bottom: 10px;
						}
						position: relative;
						&:hover .sharemenu{display: block;}
					}
				}
			}

			.people_list{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 51px;
				background: #000000;
				border-radius: 7px;
				font-size: 14px;
				.down{
					width: 20px;
					height: 20px;
					margin-right: 10px;
				}
			}
			.menu_box{display: flex;align-items: center;height: 69px;background: #000000;border-radius: 7px;padding: 0 30px;margin-bottom: 10px;box-sizing: border-box;color: #fff;
				.menu{font-size: 15px;width: 120px;text-align: center;margin-left: 30px;cursor: pointer;
					&.active{color: #567BB6;position: relative;
						&:before{content:""; width: 43px;height: 3px;background: #567BB6;border-radius: 7px;position: absolute;bottom: -10px;left: 50%;margin-left: -20px;}
					}
				}
				
			}
			.menu_box1{
				display: flex;
				align-items: center;
				width: 100%;
				height: 69px;
				background: #000000;
				border-radius: 7px;
				padding: 0 30px;
				margin-top:  20px;
				margin-bottom: 10px;
				margin-right: 30px;
				box-sizing: border-box;
				.menu{
					font-size: 15px;
					width: 80px;
					text-align: center;
					margin-left: 30px;
				}
				.active{
					font-size: 15px;
					color: #567BB6;
					width: 80px;
					text-align: center;
					position: relative;
					margin-left: 30px;
					.xian{
						width: 43px;
						height: 3px;
						background: #567BB6;
						border-radius: 7px;
						position: absolute;
						bottom: -10px;
						left: 50%;
						margin-left: -20px;
					}
				}
			}
		
			.center_pin_list{
				.pin_list{
					padding: 20px;
					background-color: #000000;
					margin-bottom: 10px;
					.list_top{
						display: flex;
						justify-content: space-between;
						border-bottom: 1px solid #567BB6;
						.top_left{
							.left_title{
								font-size: 17px;
								font-weight: bold;
								display: flex;
								align-items: center;
								margin-bottom: 10px;
								.title_card{
									font-size: 10px;
									border-radius: 7px;
									border: 1px solid #B65664;
									padding: 2px 5px;
									margin-left: 10px;
									flex-shrink: 0;
								}
							}
							.left_card{
								display: flex;
								align-items: center;
								flex-wrap: wrap;
								font-size: 10px;
								margin-bottom: 20px;
								.card1{
									min-width: 77px;
									height: 17px;
									background: #567BB6;
									border-radius: 7px;
									border: 1px solid #567BB6;
									text-align: center;
									line-height: 17px;
									padding:0 5px;
									box-sizing: border-box;
									margin-right: 10px;
								}
								.card2{
									min-width: 77px;
									height: 17px;
									background: #B65664;
									border-radius: 7px;
									border: 1px solid #B65664;
									text-align: center;
									line-height: 17px;
									padding:0 5px;
									box-sizing: border-box;
									margin-right: 10px;
								}
							}
							.left_time{
								color: #567BB6;
								font-size: 12px;
								margin-bottom: 10px;
							}
						
						}
						.top_right{
							.right_list{
								font-size: 12px;
								display: flex;
								align-items: center;
								margin-bottom: 10px;
								.list_text{
									width: 60px;
								}
								.list_card{
									width: 160px;
									height: 26px;
									padding-left: 5px;
									border-radius: 7px;
									border: 1px solid #567BB6;
									display: flex;
									align-items: center;
								}
							}
						}
					}
					.list{
						padding: 10px;
						background-color: #16171C;
						display: flex;
						justify-content: space-between;
						margin: 10px 0;
						.top_right{
							.right_list{
								font-size: 12px;
								display: flex;
								align-items: center;
								margin-bottom: 10px;
								.list_text{
									width: 60px;
								}
								.list_card{
									width: 160px;
									height: 26px;
									padding-left: 5px;
									border-radius: 7px;
									border: 1px solid #567BB6;
									display: flex;
									align-items: center;
								}
							}
						}
						.list_left{
							display: flex;
							
							width: 590px;
							.left_user{
								width: 30px;
								height: 30px;
								margin-right: 10px;
							}
							.left_msg{
								.msg_name{
									font-size: 12px;
									font-weight: bold;
									margin-bottom: 10px;
								}
								.msg_text{
									font-size: 10px;
									line-height: 1.3;
								}
							}
						}
					}
				}
				.home_list{
					width: 100%;
					border-radius: 7px;
					box-sizing: border-box;
					padding: 17px 35px;
					background-color: #000000;
					margin-bottom: 17px;
					
					.list_title{
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 17px;
						.title_img{
							width: 42px;
							height: 42px;
							border-radius: 2px;
							 margin-right: 20px;
						}
						.list_user{
							flex:1;
							.user{
								font-size: 14px;
								margin-bottom: 10px;
								font-weight: bold;
							}
							.user_msg{
								font-size: 12px;
							}
						}
						
						.el-button{
							background-color: #567BB6;
							border: none;
						}
						.active{
							color: #2D2D2E;
							background-color: #F3F1EA;
							border: 1px solid #567BB6;
						}
					}
					.list_text{
						font-size: 14px;
						line-height: 1.3;
						margin-bottom: 20px;
					}
					.img_list{
						display: flex;
						flex-wrap: wrap;
						
						.img{
							width: 280px;
							height: 191px;
							margin:0 9px 9px 0;
						}
					}
					.like_box{
						height: 30px;
						padding: 0 8px;
						display: flex;
						align-items: center;
						border-top: 1px solid #567BB6;
						font-size: 12px;
						.like_img{
							width: 20px;
							height: 20px;
							margin-right: 6px;
						}
					}
					.btn_box{
						display: flex;
						align-items: center;
						justify-content: space-between;
						height: 45px;
						border-top: 1px solid #567BB6;
						border-bottom: 1px solid #567BB6;
						.btn_left{
							display: flex;
							align-items: center;
							margin-left: 85px;
							.left_menu{
								display: flex;
								align-items: center;
								margin-right: 70px;
								font-size: 14px;
								font-weight: bold;
								position: relative;
								&:hover .sharemenu{display: block;}
								.btn1{
									width: 24px;
									height: 24px;
									margin-right: 8px;
								}
								.btn2{
									width: 21px;
									height: 21px;
									margin-right: 8px;
								}
								.btn3{
									width: 19px;
									height: 22px;
									margin-right: 8px;
								}
							}
						}
						.right_btn{
							width: 27px;
							height: 27px;
						}
					}
					.input_box{
						width: 100%;
						height: 38px;
						background: #FFFFFF;
						border-radius: 9px;
						margin: 15px 0;
						box-sizing: border-box;
						font-size: 14px;
					}
					.input_add{
						display: flex;
						align-items: center;
						justify-content: space-between;
						height: 42px;
						border-bottom: 1px solid #567BB6;
						.biaoqin{
							width: 25px;
							height: 25px;
						}
						.el-button{
							background-color: #567BB6;
							border: none;
						}
					}
					.pin_list2{
						width: 100%;
						.list_box{
							width: 100%;
							margin: 15px 0;
							display: flex;
							.box_img{
								width: 34px;
								height: 34px;
								margin-right: 15px;
								border-radius: 2px;
								flex-shrink: 0;
							}
							.box_right{
								width: 100%;
								.right_user{
									display: flex;
									justify-content: space-between;
									align-items: center;
									.user_name{
										.name{
											
											overflow: hidden;
											text-overflow: ellipsis;
											white-space: normal;
											font-size: 12px;
											margin-bottom: 10px;
											font-weight: bold;
										}
										.name_time{
											font-size: 10px;
										}
									}
									.user_btn{
										display: flex;
										align-items: center;
										.btn1{
											width: 24px;
											height: 24px;
											margin-left: 20px;
										}
										.btn2{
											width: 21px;
											height: 21px;
											margin-left: 20px;
										}
										.btn3{
											width: 24px;
											height: 24px;
											margin-left: 20px;
										}
									}
								}
								.right_msg{
									font-size: 12px;
									margin: 10px 0;
									line-height: 1.3;
								}
								.right_more{
									font-size: 10px;
									display: flex;
									align-items: center;
									border-left: 1px solid #DADADA;
									.more_user{
										color: #567BB6;
										margin: 0 5px;
									}
								}
							}
						}
					}
					.pin_more{
						font-size: 14px;
						text-align: center;
						padding-top: 15px;
						border-top: 1px solid #567BB6;
						.el-button{
							background-color: #F3F1EA;
							border: none;
							font-size: 14px;
							color: #2D2D2E;
							font-weight: bold;
						}
					}
				}
				.people_list2{
					width: 100%;
					box-sizing: border-box;
					padding: 20px;
					background: #000000;
					border-radius: 7px;
					color: #fff;
					.list_box{
						height: 84px;
						border-bottom: 1px solid #567BB6;
						display: flex;
						align-items: center;
						justify-content: space-between;
						.list_img{
							width: 43px;
							height: 43px;
							margin-right: 15px;
						}
						.list_text{
							width: 560px;
							.text_name{
								display: flex;
								align-items: center;
								font-size: 14px;
								color: #fff;
								margin-bottom: 10px;
								font-weight: bold;
								span{
									font-weight: normal;
									font-size: 12px;
									margin-left: 10px;
								}
							}
							.text_msg{
								font-size: 12px;
								line-height: 1.3;
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
							}
						}
						.btn_right{
							display: flex;
							align-items: center;
							.more{
								width: 27px;
								height: 27px;
								margin-left: 15px;
							}
							.btn1{
								width: 86px;
								height: 34px;
								background: #567BB6;
								border-radius: 7px;
								display: flex;
								align-items: center;
								justify-content: center;
								border: none;
							}
							.btn2{
								width: 86px;
								height: 34px;
								border: 1px solid #567BB6;
								background-color: #000;
								color: #FFF;
								border-radius: 7px;
								display: flex;
								align-items: center;
								justify-content: center;
							}
							.btn3{
								width: 86px;
								height: 34px;
								border: 1px solid #B65664;
								background-color: #000;
								color: #FFF;
								border-radius: 7px;
								display: flex;
								align-items: center;
								justify-content: center;
							}
						}
					}
					.list_none{
						text-align: center;
						font-size: 14px;
						line-height: 40px;
						font-weight: bold;
						color: #fff;
					}
				}
			
			}
		}
	}
	.pin_more{
		font-size: 14px;
		text-align: center;
		padding-top: 15px;
		border-top: 1px solid #567BB6;
		.el-button{
			background-color: #000 !important;
			border: none;
			font-size: 14px;
			color: #fff !important;
			font-weight: bold;
		}
	}
</style>
